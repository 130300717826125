class Controller {

  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;

    this.$q.all([  
      this.$resourceManager.request('participants', 'get', {id: this.$stateParams.id}),
      this.$resourceManager.request('line_items', 'query', {participant_id: this.$stateParams.id}),
      this.$resourceManager.request('guardianships', 'query', {participant_id: this.$stateParams.id}),
    ]).then(responses => {
      const [ participant, line_items, guardianships ] = responses;

      this.participant = participant.participant;
      this.line_items = line_items.line_items;
      this.guardianships = guardianships.guardianships;

      var customerIds = this.guardianships.map(guardianship => guardianship.customer_id);

      if (customerIds.length > 0) {
        this.$resourceManager.request('customers', 'query', {'id[]': customerIds}).then(response => {
          this.customers = response.customers;
        });
      }

      this.build(this.participant);

    });
  }

  build(participant) {
    this.participant.dob = new Date(moment(participant.dob).toString());
    this.displayedFirstName = participant.first_name;
    this.displayedLastName = participant.last_name;
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
    this.build(this.course);
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    var _participant = angular.copy(this.participant);
    this.$resourceManager.save('participants', {id: this.participant.id}, _participant).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };


}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui'];

export const participantShow = {
  controller: Controller,
  template: require('./participantShow.html')
}