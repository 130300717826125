class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;

    this.loading = true;

    this.$q.all([
      this.$resourceManager.request('holidays', 'get', {id: this.$stateParams.id}),
    ]).then(responses => {
      const [ holiday ] = responses;

      this.holiday = holiday.holiday;
      this.build(this.holiday);

      this.$resourceManager.request('customers', 'get', {id: this.holiday.customer_id}).then(response => {
        this.customer = response.customer;
        this.loading = false;
      });
    
      this.$resourceManager.request('destinations', 'get', {id: this.holiday.destination_id}).then(response => {
        this.destination = response.destination;
      });

    });
  }

  build(holiday) {
    this.holiday.price_view = holiday.price / 100;
    if (holiday.date_start) this.holiday.date_start = new Date(moment(holiday.date_start).toString());
    if (holiday.date_end) this.holiday.date_end = new Date(moment(holiday.date_end).toString());
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
    this.build(this.enquiry);
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    var _holiday = angular.copy(this.holiday);
    _holiday.date_start = this.holiday.date_start;
    _holiday.date_end = this.holiday.date_end;
    _holiday.price = this.holiday.price_view * 100;
    this.$resourceManager.save('holidays', {id: this.holiday.id}, _holiday).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  }

}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui'];

export const holidayShow = {
  controller: Controller,
  template: require('./holidayShow.html')
}