class Controller {

  constructor($notifier, $editState, $resourceManager, $stateParams, $ui, $api, UserService) {

    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$stateParams = $stateParams;
    this.ui = $ui;
    this.$api = $api;
    this.user = UserService.user;

    this.shirtOptions = [{value: "XS youth (5-6)"}, {value: "S youth (7 -8)"}, {value: "M youth (9-10)"}, {value: "L youth (11 -12)"}, {value: "XL youth (13)"}, {value: "Small adult (14 +)"}];
    this.shortsOptions = [{value: "XS youth (5-6)"}, {value: "S youth (7 -8)"}, {value: "M youth (9-10)"}, {value: "L youth (11 -12)"}, {value: "XL youth (13)"}, {value: "Small adult (14 +)"}];
    this.socksOptions = [{value: "13k - 2"},{value: "2.5 - 6"}, {value: "6.5 - 8"}, {value: "8.5+"}, {value: "13k - 2"}];

    this.loading = true;
  }

  $onInit() {

    this.$api.get([
      { name: 'line_item', id: this.$stateParams.id }
    ]).then(([{line_item}]) => {
      this.$api.get([
        { name: 'customer', id: line_item.order_customer_id },
        { name: 'order', id: line_item.order_id },
      ]).then(([{customer}, {order}]) => {
        this.customer = customer;
        this.order = order;
        if (this.user.role === 'admin') {
          this.$api.get([
            { name: 'guardianships', customer_id: customer.id }
          ]).then(([{guardianships}]) => {
            this.$api.fill(guardianships, 'guardianships', [
              ['hasOne', 'participant']
            ]).then(res => {
              this.guardianships = res;
              this.participants = this.guardianships.map(g => g.participant);
              this.loading = false;
            });
          });
        } else {
          this.loading = false;
        }
      })
      this.$api.fill(line_item, 'line_item', [
        ['hasOne', 'participant']
      ]).then(res => this.line_item = res);
    });

  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    var _line_item = angular.copy(this.line_item);
    this.$resourceManager.save('line_items', {id: this.line_item.id}, _line_item)
      .then(this.onSave.bind(this))
      .catch(this.onError.bind(this));
  };

}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$ui', '$api', 'UserService'];

export const bookingShow = {
  controller: Controller,
  template: require('./bookingShow.html')
}