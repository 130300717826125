export const $resourceRoutes = () => {
  // Default routes created for key
  //
  //   'get'   : { method: 'GET' }
  //   'save'  : { method: 'POST'}
  //   'query' : { method: 'GET', isArray: true}
  //   'remove': { method: 'DELETE'}
  //   'delete': { method: 'DELETE'}

  var routes = {
    'courses': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/courses/new' },
      'update': { method: 'PUT', url: '/api/courses/:id' },
      'export': { method: 'POST', url: '/api/courses/:id/export' }
    },
    'customers': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/customers/new' },
      'update': { method: 'PUT', url: '/api/customers/:id' },
    },
    'destinations': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/destinations/new' },
      'update': { method: 'PUT', url: '/api/destinations/:id' },
    },
    'enquiries': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/enquiries/new' },
      'update': { method: 'PUT', url: '/api/enquiries/:id' },
    },
    'guardianships': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/guardianships/new' },
      'update': { method: 'PUT', url: '/api/guardianships/:id' },
    },
    'holidays': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/holidays/new' },
      'update': { method: 'PUT', url: '/api/holidays/:id' },
    },
    'line_items': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/line_items/new' },
      'update': { method: 'PUT', url: '/api/line_items/:id' },
    },
    'orders': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/orders/new' },
      'update': { method: 'PUT', url: '/api/orders/:id' },
    },
    'participants': {
      'query': { method: 'GET', isArray: false },
      'new': { method: 'GET', url: '/api/participants/new' },
      'update': { method: 'PUT', url: '/api/participants/:id' },
    },
  };

  return routes;
}