// import "./app.scss";

class Controller {

  constructor(UserService, Auth, $location, $notifier, $timeout, $navigator, $editState, $resourceManager, $ui) {
    this.Auth = Auth;
    this.$location = $location;
    this.$navigator = $navigator;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.UserService = UserService;
    this.user = UserService.user;
    this.ui = $ui;
  }

  // $onInit() {
    // this.$rootScope.$on("$locationChangeStart", (event) => {
    //   if (this.$editState.isDirty()) {
    //     var leave = confirm("You have unsaved changes. Are you sure you want to navigate away from this page?");
    //     if (leave) this.$editState.dirty = false;
    //     if (!leave) {
    //       event.preventDefault();
    //     } else {
    //       $('#main-section').scrollTop(0);
    //     }
    //   } else {
    //     $('#main-section').scrollTop(0);
    //   }
    // });
  // }

  // logout() {
  //   this.Auth.logout({ headers: { 'X-HTTP-Method-Override': 'DELETE' } }).then(() => {
  //     window.location = window.location.origin;
  //   }, function (error) {
  //     console.error(error);
  //   });
  // };

  logout() {
    this.Auth.logout({ headers: { 'X-HTTP-Method-Override': 'DELETE' } }).then(() => {
      this.$timeout(() => {
        window.location = window.location.origin;
        this.UserService.reset();
      }, 750);
    }, error => {
      console.log(error);
    });
  }

}

Controller.$inject = ['UserService', 'Auth', '$location', '$notifier', '$timeout', '$navigator', '$editState', '$resourceManager', '$ui'];

export const app = {
  controller: Controller,
  template: require('./app.html')
};
