export const $navigator = ($window) => {

  var navigator = {
    title: "Keeper | Football Escapes",
    section: null,
  };

  navigator.setBoth = (title = "Keeper | Football Escapes", section) => {
    navigator.setTitle(title);
    navigator.setSection(section);
  }

  navigator.setSection = function (section) {
    navigator.section = section;
  };

  navigator.setTitle = function (title) {
    navigator.title = title;
    $window.document.title = title;
  };

  return navigator;
}


$navigator.$inject = ['$window'];