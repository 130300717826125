export const $editState = () => {

  var editState = {
    dirty: false,
    isDirty: function() {
      return this.dirty;
    }
  };

  return editState;
}