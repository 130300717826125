export class $api {

  constructor($resourceManager, $q) {
    this.$resourceManager = $resourceManager;
    this.$q = $q;
  }

  get(resources) {
    return this.$q((resolve,reject) => {
      const promises = resources.map(resource => {
        const { name, ...params } = resource;
        const method = (!!name.match(/s$/)) ? 'query' : 'get';
        return this.$resourceManager.request(this.pluralize(name), method, params)
      })
      this.$q.all(promises)
        .then(res => resolve(res))
        .catch(res => reject(res))
    });
  }

  pluralize(name) {
    if (name.match(/s$/)) return name;
    return !!name.match(/y$/) ? `${name.slice(0, name.length-1)}ies` : `${name}s`;
    
  }
  
  singularize(name) {
    if (!name.match(/s$/)) return name;
    return !!name.match(/ies$/) ? `${name.slice(0, name.length-3)}y` : `${name.slice(0, name.length-1)}`;
  }

  fill(collection, parentName, relations) {
    var wasSingle = false;
    if (!Array.isArray(collection)) {
      wasSingle = true;
      collection = [collection];
    }
    return this.$q((resolve, reject) => {
      const promises = relations.map(relation => {
        const [relationType, childName] = relation;
        switch (relationType) {
          case 'hasOne': return this.getHasOne(childName, collection);
          case 'hasMany': return this.getHasMany(childName, parentName, collection);
          case 'hasOneThrough': return this.getHasOneThrough(childName, collection);
        };
      });
      this.$q.all(promises).then(responses => {
        for (let i = 0; i < relations.length; i++) {
          const response = responses[i];
          const [ relationType, childName ] = relations[i];
          collection.forEach(parent => {
            var toAttach;
            switch(relationType) {
              case 'hasOne': toAttach = _.findWhere(response, {id: parent[`${childName}_id`]}); break; 
              case 'hasMany': toAttach = _.where(response, {[`${this.singularize(parentName)}_id`]: parent.id}); break;
              case 'hasOneThrough': toAttach = _.where(response, {[`${this.singularize(parentName)}_id`]: parent.id}); break;
            }
            parent[childName] = toAttach;
          })
        }
        const toResolve = wasSingle ? collection[0] : collection;
        
        resolve(toResolve);
      });
    });
  }

  getHasOne(childName, parents) {
    const childIds = _.pluck(parents, `${childName}_id`);
    const childPlural = this.pluralize(childName);
    return this.$q((resolve, reject) => {
      if (childIds.length > 0) {
        this.$resourceManager.request(childPlural, 'query', {'id[]': childIds}).then(response => {
          resolve(response[childPlural]);
        });
      } else {
        resolve(null)
      }
    })
  }

  getHasMany(childPlural, parentPlural, parents) {

    const parentIds = parents.map(parent => parent.id);
    const parentName = this.singularize(parentPlural);

    return this.$q((resolve, reject) => {
      if (parentIds.length > 0) {
        this.$resourceManager.request(childPlural, 'query', {[`${parentName}_id[]`]: parentIds}).then(response => {
          resolve(response[childPlural]);
        });
      } else {
        resolve([]);
      }
    })
  }

}

$api.$inject = ['$resourceManager', '$q'];