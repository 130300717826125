class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui, $api) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;
  }

  $onInit() {
    this.$api.get([
      {name:'customer', id: this.$stateParams.id},
      {name:'guardianships', customer_id: this.$stateParams.id},
      {name:'orders', customer_id: this.$stateParams.id},
      {name:'holidays', customer_id: this.$stateParams.id},
      {name:'enquiries', customer_id: this.$stateParams.id}
    ]).then(([{customer}, {guardianships}, {orders}, {holidays}, {enquiries}]) => {
      this.$api.fill(guardianships, 'guardianships', [
        ['hasOne', 'participant'] 
      ]).then(guardianships => {
        customer.participants = guardianships.map(g => g.participant);
        customer.rating = customer.rating || 0;
        this.customer = customer;
        this.displayedEmail = this.customer.email;
        this.guardianships = guardianships;
        this.orders = orders;
        this.holidays = holidays;
        enquiries.forEach(enquiry => enquiry.customer = customer);
        this.enquiries = enquiries;
        this.loading = false;
      })
    })
  }


  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    var _customer = angular.copy(this.customer);
    this.$resourceManager.save('customers', {id: this.customer.id}, _customer).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };

}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui', '$api'];

export const customerShow = {
  controller: Controller,
  template: require('./customerShow.html')
}