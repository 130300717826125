export const $resourceManager = ($resourceRoutes, $resource, $q) => {

  var baseUrl = '/api/KLASS/:id';

  var manager = {
    resources: {}
  };

  angular.forEach($resourceRoutes, function (value, key) {
    var url = baseUrl.replace('KLASS', key);
    manager.resources[key] = $resource(url, null, value);
  });

  manager.save = function (klass, params, payload) {
    var method = params.id ? 'update' : 'save';
    return manager.request(klass, method, params, payload);
  };

  manager.request = function (klass, method, params, payload) {
    return $q (function (resolve, reject) {
      if (!manager.resources[klass] || !manager.resources[klass][method]) {
        throw 'Method missing from resource_routes.js -> ' + klass + ':' + method;
      }

      var innerPromise = manager.resources[klass][method](params, payload).$promise;
      innerPromise.then(function () {
        resolve(innerPromise);
      }, function () {
        reject(innerPromise.$$state.value);
      });
    });
  };

  return manager;
}

$resourceManager.$inject = ['$resourceRoutes', '$resource', '$q'];
