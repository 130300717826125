/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
// var componentRequireContext = require.context("main", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

// Import CSS files

// Vendor
import("../../client/css/select.scss");
import "../../client/css/animate.css";
import "angular-spinkit/build/angular-spinkit.min.css";


// Ours
import("../../client/css/animations.scss");
import("../../client/css/application.scss");
import("../../client/css/buttons.scss");
import("../../client/css/forms.scss");
import("../../client/css/lines.scss");
import("../../client/css/login.scss");
import("../../client/css/modals.scss");
import("../../client/css/nav.scss");
import("../../client/css/notices.scss");
import("../../client/css/pagination.scss");
import("../../client/css/reset.scss");
import("../../client/css/search.scss");
import("../../client/css/sections.scss");
import("../../client/css/spinners.scss");
import("../../client/css/typefaces.scss");
import("../../client/css/typography.scss");




// Start AngularJs
import "../angularjs/ngmodule.js";

// Start React
// import "../react/main/app.js"