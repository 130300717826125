class _Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;
  }

  $onInit() {
    this.search(1);
  }

  search(page) {

    this.loading = true;

    let params = {page: page};

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'destinations', ...params}]).then(responses => {
      const [ destinations ] = responses;
      this.destinations = destinations.destinations;
      this.updateViewControls(destinations);
      this.loading = false;
    });
  }

  updateViewControls(response) {
    if (!this.orderBy) this.orderBy = new this.OrderBy(response.meta);
    if (!this.pagination) this.pagination = new this.Pagination(response.meta.total, this.search);
    this.pagination.update();
  }

  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

_Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api'];

export const destinationsIndex = {
  controller: _Controller,
  template: require('./destinationsIndex.html')
};