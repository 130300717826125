

import * as angular from "angular";
import uiRouter from "@uirouter/angularjs";
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { DSRPlugin } from '@uirouter/dsr';
import * as $resource from 'angular-resource';
import * as ngCookies from 'angular-cookies';
import * as ngSanitize from 'angular-sanitize';
import * as angularMoment from "angular-moment";
import * as dirPagination from "angular-utils-pagination";
import * as angularSpinkit from "angular-spinkit";

require('AngularDevise/lib/devise-min');
require('jquery-base64');
require('iso-currency/dist/isoCurrency.min');
require('angular-animate/angular-animate.min');
require('angular-ui-select/dist/select.min.js');
require('./global/ngMask.min.js')

// import 'ob-daterangepicker/dist/styles/ob-daterangepicker.css';

import { GLOBAL_MODULE } from './global/global.module';
import { APP_MODULE } from './app/app.module';
import { FILTERS_MODULE } from './filters/filters.module';

_.capitalize = function (string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

_.zeroPad = function pad(n, width) {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
};

_.camelize = function camelize(str) {
  return str.replace('_', ' ').replace(/\W+(.)/g, function(match, chr) {
    return chr.toUpperCase();
  });
};

export const ngmodule = angular.module("keeperApp", [
  uiRouter,
  $resource,
  ngCookies,
  ngSanitize,
  angularMoment,
  dirPagination,
  'angular-spinkit',
  'Devise',
  'isoCurrency',
  'ngAnimate',
  'ngMask',
  'ui.select',

  APP_MODULE.name,
  GLOBAL_MODULE.name,
  FILTERS_MODULE.name,

]);

ngmodule.config(['$uiRouterProvider', '$locationProvider', '$provide', ($uiRouter, $locationProvider, $provide) => {

  $locationProvider.hashPrefix('');
  $uiRouter.plugin(StickyStatesPlugin);
  $uiRouter.plugin(DSRPlugin);

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  // // Show the UI-Router Visualizer
  // import("@uirouter/visualizer").then(module => $uiRouter.plugin(module.Visualizer));

  $provide.decorator('$location', ["$delegate", function($delegate) {

    $delegate.addParams = object => {
      let params = $delegate.search();
      angular.forEach(object, (value, key) => {
        params[key] = value;
      });
      return $delegate.search(params);
    };

    $delegate.removeParams = keys => {
      keys = (Array.isArray(keys)) ? keys : [keys];
      let params = $delegate.search();
      keys.forEach(key => delete params[key]);
      return $delegate.search(params);
    };

    return $delegate;
  }]);

}]);

// .run(['ActionCableConfig', function (ActionCableConfig) {
//   ActionCableConfig.autoStart = false;
// }]);
