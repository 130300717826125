export const $notifier = ($timeout) => {

  var notifier = {};

  var create = function(type, text, persist) {
    notifier.type = type;
    notifier.text = text;
    notifier.active = true;
    $timeout(function() {
      if (!persist) notifier.dismiss();
    }.bind(notifier), 3000);
  };

  notifier.green = function(text) {
    create('success', text);
  };

  notifier.red = function(text) {
    create('error', text);
  };

  notifier.dismiss = function() {
    this.active = false;
    this.type = null;
    this.text = null;
  };

  return notifier;

}

$notifier.$inject = ['$timeout'];
