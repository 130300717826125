export const OrderBy = () => {

  function _OrderBy (meta) {
    this.order_by = meta.order_by;
    this.order_direction = meta.order_direction;
    this.setOrderBy = column => {
      if (column === this.order_by || column === "name" && this.order_by === "last_name" ) {
        this.order_direction = (this.order_direction === "DESC") ? "ASC" : "DESC";
      } else {
        this.order_by = (column === "name") ? "last_name" : column;
        this.order_direction = "DESC";
      }
    }
  }

  return _OrderBy;
}

