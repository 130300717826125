import "./starRating.scss";

export const starRating = ($timeout) => {

  var directive = { };
  directive.restrict = 'E';
  
  directive.scope = {
    score: '=score',
    max: '=max',
    onSet: '&'
  };

  directive.template = `
    <div class="rating">
      <a ng-repeat="star in stars" ng-mouseover="hover($index)" ng-mouseleave="stopHover()"
        ng-class="starColor($index)" ng-click="setRating($index)">
        <span class="fa" ng-class="starClass(star, $index)"></span>
      </a>
      <span class="dismiss-standard" ng-click='setRating(-1)' ng-class='{available: score > 0}'>&times;</span>
    </div>
  `;
  
  directive.link = function(scope, elements, attr) {
    
    scope.updateStars = function() {
      var idx = 0;
      scope.stars = [ ];
      for (idx = 0; idx < scope.max; idx += 1) {
        scope.stars.push({
          full: scope.score > idx
        });
      }
    };
    
    scope.hover = function(/** Integer */ idx) {
      scope.hoverIdx = idx;
    };
    
    scope.stopHover = function() {
      scope.hoverIdx = -1;
    };
    
    scope.starColor = function(/** Integer */ idx) {
      var starClass = 'rating-normal';
      if (idx <= scope.hoverIdx) {
       starClass = 'rating-highlight'; 
      }
      return starClass;
    };
    
    scope.starClass = function(/** Star */ star, /** Integer */ idx) {
      var starClass = 'fa-star-o';
      if (star.full || idx <= scope.hoverIdx) {
        starClass = 'fa-star';
      }
      return starClass;
    };
    
    scope.setRating = function(idx) {
      scope.score = idx + 1;
      scope.stopHover();
      $timeout(() => scope.onSet());
    };
    
    scope.$watch('score', function(newValue, oldValue) {
      if (newValue !== null && newValue !== undefined) {
        scope.updateStars();
      }
    });
  };
  
  return directive;
};

starRating.$inject = ['$timeout'];

