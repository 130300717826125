class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;

    this.loading = true;
  
    this.$q.all([  
      this.$resourceManager.request('orders', 'get', {id: this.$stateParams.id}),
      this.$resourceManager.request('line_items', 'query', {order_id: this.$stateParams.id}),
    ]).then(responses => {
      const [ order, line_items ] = responses;

      this.order = order.order;
      this.order.amount_paid_view = this.order.amount_paid / 100;
      this.order.discount_amount_view = this.order.discount_amount / 100;
  
      this.line_items = line_items.line_items;

      this.build();

      var participantIds = _.pluck(this.line_items, 'participant_id');

      if (participantIds.length > 0) {
        this.$resourceManager.request('participants', 'query', {'id[]': participantIds}).then(response => {
          this.line_items.forEach(line_item => {
            line_item.participant = _.findWhere(response.participants, {id: line_item.participant_id});
          });
        });
      }

      this.$resourceManager.request('customers', 'get', {id: this.order.customer_id}).then(response => {
        this.customer = response.customer;
        this.$resourceManager.request('guardianships', 'query', {'customer_id': this.customer.id}).then(response => {
          this.$resourceManager.request('participants', 'query', {'id[]': _.pluck(response.guardianships, 'participant_id')}).then(response => {
            if (response.participants)  this.modalSelectOptions = { participant_id: response.participants };
            this.loading = false;
          });
        });
      });

      this.form.$setPristine();
      this.$editState.dirty = false;

    });

  }

  build() {
    this.displayedOrderID = this.order.foreign_id;
    this.lineItemParentIds = {order_id: this.order.id};
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.$notifier.green('Saved.');
    this.form.$setPristine();
    this.$editState.dirty = false;
    this.build();
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    var _order = angular.copy(this.order);
    _order.amount_paid = this.order.amount_paid_view * 100;
    _order.discount_amount = this.order.discount_amount_view * 100;
    this.$resourceManager.save('orders', {id: this.order.id}, _order).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };


}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui'];

export const orderShow = {
  controller: Controller,
  template: require('./orderShow.html')
}
