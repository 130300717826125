export const UserService = () => {

  let service = {
    user: {}
  }

  service.initialize = (user) => {
    let builtUser = {
      // Merge in extra boolean helpers here, eg, 'isSubscriber'
      ...user
    };

    angular.copy(builtUser, service.user);
    return service.user;
  }

  service.defaultState = () => {
    return (service.user.role === 'admin') ? 'customersIndex' : 'coursesIndex';
  }

  service.reset = () => {
    angular.copy({}, service.user);
  }

  return service;

}