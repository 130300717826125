export const dateTextBox = () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attributes, ngModel) {

      var dateTestRegex = /\d{1,2}-\d{1,2}-\d{4}/;

      ngModel.$parsers.push(parser);
      ngModel.$formatters.push(formatter);

      function parser(value) {
        if (dateTestRegex.test(value) && moment(value, "DD-MM-YYYY").isValid()) {
          value = moment(value, "DD-MM-YYYY");
          ngModel.$setValidity('textDate', true);
        } else {
          value = null;
          ngModel.$setValidity('textDate', false);
        }
        return value;
      }

      function formatter(value) {
        var formatted = '';
        if (angular.isDate(value)) {
          formatted = _.zeroPad(value.getDate(), 2) + '-' + _.zeroPad((value.getMonth() + 1), 2) + '-' + value.getFullYear();
        }
        return formatted;
      }

    }
  };
}