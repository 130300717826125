class Controller {

  constructor(Auth, $location, $timeout, UserService, $state) {
    this.Auth = Auth;
    this.$location = $location
    this.$timeout = $timeout
    this.UserService = UserService;
    this.stateService = $state;
    this.error = null;

    this.user = {
      email: null,
      password: null
    };
  }

  onError(response) {
    this.login.$submitted = false;
    this.error = response.data.errors[0];
  };

  onSuccess(response) {
    this.UserService.initialize(response);
    const state = this.UserService.defaultState();
    this.stateService.go(state);
  };

  submit() {
    this.error = null;
    this.Auth.login(this.user, { headers: { 'X-HTTP-Method-Override': 'POST'} })
      .then(this.onSuccess.bind(this))
      .catch(this.onError.bind(this));
  };

}

Controller.$inject = ['Auth', '$location', '$timeout', 'UserService', '$state'];

export const login = {
  template: require('./login.html'),
  controller: Controller,
};