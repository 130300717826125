export const pagination = () => {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      pagination: '=',
      object: '@'
    },
    template: require('./pagination.html')
  };
};