export class $ui {
  constructor($location, $notifier, $resourceManager) {
    this.$location = $location;
    this.$notifier = $notifier;
    this.$resourceManager = $resourceManager;
    this.showDeleteConfirm = false;
    this.showNewObject = false;
    this.newObject = {};
    this.deleteModal = {};
  }

  newObjectModel(klass, parentIds, modalSelectOptions, redirectSlug, title) {
    this.newObject.klass = klass;
    this.newObject.parentIds = parentIds;
    this.newObject.modalSelectOptions = modalSelectOptions;
    this.newObject.redirectSlug = redirectSlug;
    this.newObject.title = title;
    this.showNewObject = true;
  }

  newDeleteModal(klass, id, description, redirectSlug) {
    this.deleteModal.klass = klass;
    this.deleteModal.description = description;
    this.deleteModal.id = id;
    this.showDeleteConfirm = true;
    this.deleteModal.destroy = () => {
      this.$resourceManager.request(klass, 'delete', {id: id}).then(() => {
        this.deleteModal.text = "";
        this.$notifier.green("Deleted.");
        this.$location.path("/" + ((redirectSlug) ? redirectSlug : klass));
        this.showDeleteConfirm = false;
      });
    };
  };
}

$ui.$inject = ['$location', '$notifier', '$resourceManager'];