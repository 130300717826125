class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui, $api, UserService) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.user = UserService.user;
    this.loading = true;

    this.$api.get([
      { name: 'course', id: this.$stateParams.id},
      { name: 'destinations' },
      { name: 'line_items', course_id: this.$stateParams.id}
    ]).then(([{course}, {destinations}, {line_items}]) => {

      this.course = course;
      this.destinations = destinations;

      if (this.user === 'admin') {
        this.$api.fill(line_items, 'line_items', [
          ['hasOne', 'order']
        ]).then(res => {
          this.course.line_items = res;
          this.build(this.course);
          this.loading = false;
        });
      } else {
        this.course.line_items = line_items;
        this.build(this.course);
        this.loading = false;
      }

    });

  }

  build(course) {
    this.displayedDateStart = course.date_start;
    this.displayedDateEnd = course.date_end;
    this.course.date_start = new Date(moment(course.date_start).toString());
    this.course.date_end = new Date(moment(course.date_end).toString());
    this.course.destination = _.findWhere(this.destinations, {id: course.destination_id});
    if (this.user.role == 'admin') {
      this.course.total_revenue = this.calculateTotalRevenue(course);
    }
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave(skipNotification) {
    this.form.$setPristine();
    if (!skipNotification) this.$notifier.green('Saved.');
    this.$editState.dirty = false;
    this.build(this.course);
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    const { date_start, date_end, foreign_id, destination_id, notes } = this.course;
    const _course = { date_start, date_end, foreign_id, destination_id, notes };
    this.$resourceManager.save('courses', {id: this.course.id}, _course).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };

  setStatus(status, skipNotification) { 
    var _course = { status: status };
    this.$resourceManager.save('courses', {id: this.course.id}, _course).then(() => {
      this.course.status = status;
      this.onSave(skipNotification);
    }).catch(this.onError.bind(this));
  };

  calculateTotalRevenue(course) {
    return course.line_items.reduce(function(num, line_item) {
      var percentage_of_order = line_item.price / (line_item.order_amount_paid + line_item.order_discount_amount);
      if (isNaN(percentage_of_order)) {
        num += 0;
        return num;
      }
      num += percentage_of_order * line_item.order_amount_paid;
      return num
    }, 0);
  }


}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui', '$api', 'UserService'];

export const courseShow = {
  controller: Controller,
  template: require('./courseShow.html')
}

 // def total_revenue
  //   total = LineItem.where(course_id: id).inject(0) do |num, line_item|
  //     percentage_of_order = line_item.price.to_f / (line_item.order.amount_paid.to_f + line_item.order.discount_amount.to_f)
  //     num += percentage_of_order * line_item.order.amount_paid
  //   end
  //   total.to_i
  // end