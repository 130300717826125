export const searchAttach = ($resourceManager) => {

  return {
    restrict: 'E',
    scope: {
      modelName: '@',
      searchField: '@',
      placeholder: '@',
      allowClear: '=',
      selected: '=',
    },
    template: require('./searchAttach.html'),
    link: function(scope) {

      scope.selected = scope.selected || {};

      scope.search = {
        text: '',
        onType: function($select) {
          var text = $select.search;
          if (!text || text.length === '') return scope.items = [];
          var searchParams = {};
          searchParams[scope.searchField] = text;
          $resourceManager.request(scope.modelName, 'query', searchParams).then(function(response) {
            scope.items = response[scope.modelName];
          });
        },

        onSelect: function($select) {
          
          if ($select.search === "" && $select.selected === null) {
            $select.items = [];
            scope.selected = null;
            return null;
          } else {
            scope.selected = $select.selected;
          }
        }

      };

    }
  };
}

searchAttach.$inject = ['$resourceManager'];