class Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api, UserService) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.user = UserService.user;
    this.loading = true;
  }

  $onInit() {
    this.search(1);
  }

  search(page) {

    this.loading = true;

    let params = {page: page, per_page: 20};

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'courses', ...params}]).then(responses => {
      const [ res1 ] = responses;
      var { courses, meta } = res1;

      this.$api.fill(courses, 'courses', [
        ['hasOne', 'destination'],
        // ['hasMany', 'line_items'],
      ]).then(filled => {
        this.loading = false;
        this.courses = filled
        this.updateViewControls(meta);
      });

    });
  }

  updateViewControls(meta) {
    if (!this.orderBy) this.orderBy = new this.OrderBy(meta);
    if (!this.pagination) this.pagination = new this.Pagination(meta.total, this.search.bind(this));
    this.pagination.update();
  }
  
  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api', 'UserService'];

export const coursesIndex = {
  controller: Controller,
  template: require('./coursesIndex.html')
};