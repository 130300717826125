import { UserService } from "./user.service.js";
import { OrderBy } from "./orderBy.factory";
import { Pagination } from "./pagination/pagination.factory";
import { pagination } from "./pagination/pagination.directive";
import { bookingList } from "./booking_list/bookingList.directive";
import { searchAttach } from "./search_attach/searchAttach.directive";
import { searchBox } from "./search_box/searchBox.directive";
import { selectBox } from "./select_box/selectBox.directive";
import { dateTextBox } from "./dateTextBox.directive";
import { currencyMask } from "./currencyMask.directive";
import { newObjectDialog } from "./new_object_dialog/newObjectDialog.directive";
import { $resourceRoutes } from "./resourceRoutes.service";
import { $resourceManager } from "./resourceManager.service";
import { $editState } from "./editState.service";
import { $navigator } from "./navigator.service";
import { $ui } from "./ui.service";
import { $notifier } from "./notifier.service";
import { $objectsAvailable } from "./objectsAvailable.service";
import { $api } from "./api.service";
import { $redirects } from "./redirects.service";
import { starRating } from "./star_rating/starRating.directive";
import { splitButton } from "./split_button/splitButton.directive";

import { pageChangeHook } from "./pageChange.hook";

export const GLOBAL_MODULE = angular.module('global', []);

GLOBAL_MODULE.service('UserService', UserService);
GLOBAL_MODULE.factory('OrderBy', OrderBy);
GLOBAL_MODULE.factory('Pagination', Pagination);
GLOBAL_MODULE.directive('pagination', pagination);
GLOBAL_MODULE.directive('bookingList', bookingList);
GLOBAL_MODULE.directive('newObjectDialog', newObjectDialog);
GLOBAL_MODULE.directive('searchAttach', searchAttach);
GLOBAL_MODULE.directive('searchBox', searchBox);
GLOBAL_MODULE.directive('dateTextBox', dateTextBox);
GLOBAL_MODULE.directive('starRating', starRating);
GLOBAL_MODULE.directive('currencyMask', currencyMask);
GLOBAL_MODULE.directive('splitButton', splitButton);
GLOBAL_MODULE.directive('selectBox', selectBox);
GLOBAL_MODULE.service('$editState', $editState);
GLOBAL_MODULE.service('$navigator', $navigator);
GLOBAL_MODULE.service('$notifier', $notifier);
GLOBAL_MODULE.service('$ui', $ui);
GLOBAL_MODULE.service('$api', $api);
GLOBAL_MODULE.service('$redirects', $redirects);
GLOBAL_MODULE.service('$objectsAvailable', $objectsAvailable);
GLOBAL_MODULE.service('$resourceManager', $resourceManager);
GLOBAL_MODULE.service('$resourceRoutes', $resourceRoutes);


GLOBAL_MODULE.run(pageChangeHook);
