export const $objectsAvailable = () => {

  var objectsAvailable = {
    course: {
      destination_id: { type: 'parent-select', label: 'Destination', errorTip: 'Please select a destination.', placeholder: 'Please select a destination...', class: 'destinations', optionLabelProperty: 'name' },
      date_start:     { type: 'date',   label: 'Start date', errorTip: 'Invalid date' },
      date_end:       { type: 'date',   label: 'End date', errorTip: 'Invalid date' },
    },
    destination: {
      name:           { type: 'string',   label: 'Destination name', errorTip: 'Please enter a name' }
    },
    customer: {
      email:          { type: 'string',   label: 'Email address', errorTip: 'Please enter an email address' },
      first_name:     { type: 'string',   label: 'First name', errorTip: 'Please enter a first name' },
      last_name:      { type: 'string',   label: 'Last name', errorTip: 'Please enter a last name' },
    },
    participant: {
      customer_id:    { type: 'parent-search', label: 'Customer', errorTip: 'Please select a customer.', placeholder: 'Search by email...', class: 'customers', searchField: 'email_like' },
      first_name:     { type: 'string',   label: 'First name', errorTip: 'Please enter a first name' },
      last_name:      { type: 'string',   label: 'Last name', errorTip: 'Please enter a last name' },
      dob:            { type: 'date',   label: 'Date of birth', errorTip: 'Invalid date' },
    },
    enquiry: {
      customer_id:    { type: 'parent-search', label: 'Customer', errorTip: 'Please select a customer.', placeholder: 'Search by email...', class: 'customers', searchField: 'email_like' },
      notes:          { type: 'text',   label: 'Internal notes', errorTip: 'Type some internal notes...' },
    },
    order: {
      customer_id:    { type: 'parent-search', label: 'Customer', errorTip: 'Please select a customer.', placeholder: 'Search by email...', class: 'customers', searchField: 'email_like' },
    },
    line_item: {
      course_id:      { type: 'parent-select', label: 'Course', errorTip: 'Please select a course.', placeholder: 'Please select a course...', class: 'courses', optionLabelProperty: 'name' },
      participant_id: { type: 'parent-select', label: 'Participant', errorTip: 'Please select a participant.', placeholder: 'Please select a participant...', class: 'participants', optionLabelProperty: 'name' },
    },
    holiday: {
      customer_id:    { type: 'parent-search', label: 'Customer', errorTip: 'Please select a customer.', placeholder: 'Search by email...', class: 'customers', searchField: 'email_like' },
      destination_id: { type: 'parent-select', label: 'Destination', errorTip: 'Please select a destination.', placeholder: 'Please select a destination...', class: 'destinations', optionLabelProperty: 'name' },
    }
  };

  return objectsAvailable;
}