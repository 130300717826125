const keys = ["Enquiry", "ITC", "Order"];

export const optin = () => {
  return function(obj) {
    if (!obj) return null;
    var elements = "";
    keys.forEach(function(key) {
      var optinState = (!!obj.hasOwnProperty(key.toLowerCase()) && !!obj[key.toLowerCase()]) ? "yes" : "no";
      elements += "<span class='optin-" + optinState + "'>" + key + " </span>";
    });
    return elements;
  };
}
