class Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;

    this.exportOptions = [
      { value: 30, label: "Last 30 days" },
      { value: 90, label: "Last 90 days" },
      { value: 365, label: "Last 1 year" },
      { value: 99999, label: "All-time" },
    ];
  
    this.exportDaysOption = 90;

  }

  $onInit() { this.search(1);}

  search(page) {

    this.loading = true;

    let params = {page: page};

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'holidays', ...params}]).then(responses => {
      const [ res1 ] = responses;
      var { holidays, meta } = res1;

      this.$api.fill(holidays, 'holidays', [
        ['hasOne', 'customer'],
        ['hasOne', 'destination'],
      ]).then(filled => {
        this.holidays = filled
        this.updateViewControls(meta);
        this.loading = false;
      });

    });
  }

  updateViewControls(meta) {
    if (!this.orderBy) this.orderBy = new this.OrderBy(meta);
    if (!this.pagination) this.pagination = new this.Pagination(meta.total, this.search.bind(this));
    this.pagination.update();
  }
  
  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api'];

export const holidaysIndex = {
  controller: Controller,
  template: require('./holidaysIndex.html')
};