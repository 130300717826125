import { app } from "./app.component.js";
import { login } from "./login/login.component.js";
import { bookingsIndex } from "./bookings/bookingsIndex.component.js";
import { bookingShow } from "./bookings/bookingShow.component.js";
import { customersIndex } from "./customers/customersIndex.component.js";
import { customerShow } from "./customers/customerShow.component.js";
import { ordersIndex } from "./orders/ordersIndex.component.js";
import { orderShow } from "./orders/orderShow.component.js";
import { coursesIndex } from "./courses/coursesIndex.component.js";
import { courseShow } from "./courses/courseShow.component.js";
import { participantsIndex } from "./participants/participantsIndex.component.js";
import { participantShow } from "./participants/participantShow.component.js";
import { enquiriesIndex } from "./enquiries/enquiriesIndex.component.js";
import { enquiryShow } from "./enquiries/enquiryShow.component.js";
import { holidaysIndex } from "./holidays/holidaysIndex.component.js";
import { holidayShow } from "./holidays/holidayShow.component.js";
import { destinationsIndex } from "./destinations/destinationsIndex.component.js";
import { destinationShow } from "./destinations/destinationShow.component.js";


export const APP_MODULE = angular.module('app', []);

APP_MODULE.component('app', app);
APP_MODULE.component('login', login);
APP_MODULE.component('bookingsIndex', bookingsIndex);
APP_MODULE.component('bookingShow', bookingShow);
APP_MODULE.component('customersIndex', customersIndex);
APP_MODULE.component('customerShow', customerShow);
APP_MODULE.component('ordersIndex', ordersIndex);
APP_MODULE.component('orderShow', orderShow);
APP_MODULE.component('coursesIndex', coursesIndex);
APP_MODULE.component('courseShow', courseShow);
APP_MODULE.component('participantsIndex', participantsIndex);
APP_MODULE.component('participantShow', participantShow);
APP_MODULE.component('enquiriesIndex', enquiriesIndex);
APP_MODULE.component('enquiryShow', enquiryShow);
APP_MODULE.component('holidaysIndex', holidaysIndex);
APP_MODULE.component('holidayShow', holidayShow);
APP_MODULE.component('destinationsIndex', destinationsIndex);
APP_MODULE.component('destinationShow', destinationShow);


const states = [
  {
    name: 'app',
    redirectTo: '/customers',
    component: 'app',
    authenticate: true,
    resolve: {
      user: ['Auth', 'UserService', (Auth, UserService) => {
        return Auth.currentUser().then(user => {
          UserService.initialize(user);
          return UserService.user;
        });
      }]
    }
  },
  {
    name: 'home',
    url: '/',
    redirectTo: '/login',
    parent: 'app',
  },
  {
    name: 'login',
    url: '/login',
    title: 'Log in | Football Escapes',
    component: 'login',
  },
  {
    name: 'bookingsIndex',
    url: '/bookings',
    title: 'Bookings | Football Escapes',
    section: 'bookings',
    component: 'bookingsIndex',
    parent: 'app',
  },
  {
    name: 'bookingShow',
    url: '/bookings/:id',
    title: 'View booking | Football Escapes',
    section: 'bookings',
    component: 'bookingShow',
    parent: 'app',
  },
  {
    name: 'customersIndex',
    url: '/customers',
    title: 'Customers | Football Escapes',
    section: 'customers',
    component: 'customersIndex',
    parent: 'app',
  },
  {
    name: 'customerShow',
    url: '/customers/:id',
    title: 'View customer | Football Escapes',
    section: 'customers',
    component: 'customerShow',
    parent: 'app',
  },
  {
    name: 'ordersIndex',
    url: '/orders',
    title: 'Orders | Football Escapes',
    section: 'orders',
    component: 'ordersIndex',
    parent: 'app',
  },
  {
    name: 'orderShow',
    url: '/orders/:id',
    title: 'View order | Football Escapes',
    section: 'orders',
    component: 'orderShow',
    parent: 'app',
  },
  {
    name: 'coursesIndex',
    url: '/courses',
    title: 'Courses | Football Escapes',
    section: 'courses',
    component: 'coursesIndex',
    parent: 'app',
  },
  {
    name: 'courseShow',
    url: '/courses/:id',
    title: 'View course | Football Escapes',
    section: 'courses',
    component: 'courseShow',
    parent: 'app',
  },
  {
    name: 'participantsIndex',
    url: '/participants',
    title: 'Participants | Football Escapes',
    section: 'participants',
    component: 'participantsIndex',
    parent: 'app',
  },
  {
    name: 'participantShow',
    url: '/participants/:id',
    title: 'View participant | Football Escapes',
    section: 'participants',
    component: 'participantShow',
    parent: 'app',
  },
  {
    name: 'enquiriesIndex',
    url: '/enquiries',
    title: 'Enquiries | Football Escapes',
    section: 'enquiries',
    component: 'enquiriesIndex',
    parent: 'app',
  },
  {
    name: 'enquiryShow',
    url: '/enquiries/:id',
    title: 'View enquiry | Football Escapes',
    section: 'enquiries',
    component: 'enquiryShow',
    parent: 'app',
  },
  {
    name: 'holidaysIndex',
    url: '/holidays',
    title: 'Holidays | Football Escapes',
    section: 'holidays',
    component: 'holidaysIndex',
    parent: 'app',
  },
  {
    name: 'holidayShow',
    url: '/holidays/:id',
    title: 'View holiday | Football Escapes',
    section: 'holidays',
    component: 'holidayShow',
    parent: 'app',
  },
  {
    name: 'destinationsIndex',
    url: '/destinations',
    title: 'Destinations | Football Escapes',
    section: 'destinations',
    component: 'destinationsIndex',
    parent: 'app',
  },
  {
    name: 'destinationShow',
    url: '/destinations/:id',
    title: 'View destination | Football Escapes',
    section: 'destinations',
    component: 'destinationShow',
    parent: 'app',
  },

];

APP_MODULE.config(['$uiRouterProvider', 'AuthProvider', function($uiRouter, AuthProvider) {

  AuthProvider.loginPath('/users/login.json');
  AuthProvider.logoutPath('/users/logout.json');
  AuthProvider.resetPasswordMethod('PUT');

  states.forEach(state => $uiRouter.stateRegistry.register(state));

}]);