class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;

    this.loading = true;

    this.$q.all([  
      this.$resourceManager.request('destinations', 'get', {id: this.$stateParams.id}),
      this.$resourceManager.request('courses', 'query', {destination_id: this.$stateParams.id}),
    ]).then(responses => {
      const [ destination, courses ] = responses;

      this.destination = destination.destination;
      this.destination.courses = courses.courses;

      this.loading = false;

      this.build(this.destination);

    });
  }

  build(destination) {
    this.displayed_name = destination.name;
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onSave() {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
    this.build(this.destination);
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    const { name } = this.destination;
    const _destination = { name };
    this.$resourceManager.save('destinations', {id: this.destination.id}, _destination).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };

}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui'];

export const destinationShow = {
  controller: Controller,
  template: require('./destinationShow.html')
}

 // def total_revenue
  //   total = LineItem.where(course_id: id).inject(0) do |num, line_item|
  //     percentage_of_order = line_item.price.to_f / (line_item.order.amount_paid.to_f + line_item.order.discount_amount.to_f)
  //     num += percentage_of_order * line_item.order.amount_paid
  //   end
  //   total.to_i
  // end
