export function pageChangeHook($transitions, UserService, $state, Auth, $navigator, $redirects) {

  $transitions.onBefore({}, transition => {

    const toState = transition.to();
    const parent = (toState.parent) ? $state.get(toState.parent) : false;
    const { stateService } = transition.router;

    const needsAuth  = () => ((toState.authenticate || parent.authenticate));
    const userFunctionPages  = () => (['login'].includes(toState.name));

    if (toState.name == 'home') return stateService.target('login');

    if (needsAuth() || userFunctionPages()) {
      return Auth.currentUser().then(user => {

        UserService.initialize(user);

        const redirect = $redirects.check(user, toState);
        if (redirect) return stateService.target(redirect);

        return true;

      }).catch(error => {
        console.error(error);
        if (error.status == 401) {
          if (userFunctionPages()) return true;
          return stateService.target('login');
        } else {
          return stateService.target('login');
        }
      });
    } else {
      return true;
    }

  });

  //Set meta (title/section details) if page change is successful
  $transitions.onSuccess({}, transition => {
    $navigator.setBoth(transition.to().title, transition.to().section)
  });

}

pageChangeHook.$inject = ['$transitions', 'UserService', '$state', 'Auth', '$navigator', '$redirects'];
