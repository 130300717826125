export const searchBox = ($resourceManager) => {

  return {
    restrict: 'E',
    scope: {
      modelName: '@',
      collection: '=',
      searchField: '@',
      originalCollection: '=',
      placeholder: '@'
    },
    template: require('./searchBox.html'),
    link: function(scope) {
      scope.search = {
        text: '',
        onType: function(text) {
          if (!text) scope.collection = scope.originalCollection;
          var searchParams = {};
          searchParams[scope.searchField] = text;
          $resourceManager.request(scope.modelName, 'query', searchParams).then(function(response) {
            scope.collection = response[scope.modelName];
          });
        },
        clear: function() {
          this.text = '';
          scope.collection = scope.originalCollection;
        }
      };

      scope.hasFocus = false;

      scope.toggleFocus = function() {
        scope.hasFocus = !scope.hasFocus;
      };

    }
  };
}

searchBox.$inject = ['$resourceManager'];


