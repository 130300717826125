export const $redirects = (UserService) => {

  var redirects = {};

  const userAccessingUserFunctions = (user, toState) => {
    if (['login', 'signUp', 'passwordReset', 'passwordEdit'].includes(toState.name)) throw UserService.defaultState();
  }

  const coachRedirects = (user, toState) => {
    if (user.role != "admin" && !['coursesIndex', 'courseShow', 'bookingsIndex', 'bookingShow'].includes(toState.name)) throw UserService.defaultState(); 
  }
  
  redirects.check = (user, toState) => {
    if (!user) return null;
    try {
      userAccessingUserFunctions(user, toState);
      coachRedirects(user, toState);
      return false;
    } catch (url) {
      console.log(`Redirected to ${url}`);
      return url;
    }
  };

  return redirects;
}

$redirects.$inject = ['UserService'];