import { capitalize } from "./capitalize.filter";
import { optin } from "./optin.filter";
import { enumField } from "./enumField.filter";
import { fullName } from "./fullName.filter";
import { pluralize } from "./pluralize.filter";

export const FILTERS_MODULE = angular.module('filters', []);

FILTERS_MODULE.filter('capitalize', capitalize);
FILTERS_MODULE.filter('optin', optin);
FILTERS_MODULE.filter('enumField', enumField);
FILTERS_MODULE.filter('fullName', fullName);
FILTERS_MODULE.filter('pluralize', pluralize);