export const bookingList = () => {

  return {
    restrict: 'E',
    scope: {
      lineItems: '=',
      context: '@'
    },
    template: require('./bookingList.html'),
    // link: function(scope) {

    // }
  };
}

// bookingList.$inject = [];