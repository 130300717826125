export const newObjectDialog = ($resourceManager, $notifier, $location, $rootScope, pluralizeFilter, $objectsAvailable) => {

  return {
    restrict: 'E',
    scope: {
      klass: '=',
      shown: '=',
      parentIds: '=',
      selectOptions: '=',
      redirectSlug: '=',
      title: '='
    },
    template: require('./newObjectDialog.html'),
    controllerAs: 'ctrl',
    controller: ['$scope', function ($scope) {

      var onSave = function(response) {

        $scope.shown = false;
        $scope.ctrl.form.$setPristine();
        $notifier.green('Saved.');
        
        var redirectSlug = $scope.redirectSlug || pluralizeFilter($scope.klass);
        $location.path("/" + redirectSlug + '/' + response[$scope.klass].id);
      };

      var onError = function(error) {
        $notifier.red('Error.');
        console.error(error);
      };

      $scope.save = function() {
        $scope.ctrl.form.$submitted = true;
        if (!$scope.ctrl.form.$valid) return false;
        var _object = angular.copy($scope.object);

        angular.forEach($scope.parentIds, function(v, k) {
          _object[k] = v;
        });

        angular.forEach($scope.object, function(v,k) { if (v.hasOwnProperty('id')) _object[k] = v.id; });
        $resourceManager.save(pluralizeFilter($scope.klass), {id: null}, _object).then(onSave).catch(onError);
      };

    }],

    link: function(scope) {

      var reset = function() {
        scope.ctrl.form.$setPristine();
        scope.fieldData = $objectsAvailable[scope.klass];
        if (scope.selectOptions) {
          angular.forEach(scope.selectOptions, function(v, k) {
            scope.fieldData[k].selectOptions = v;
          });
        }
        scope.object = {};
      };

      $rootScope.$on("$locationChangeStart", function() {
        scope.shown = false;
      });

      scope.$watch('klass', reset);

      scope.cancel = function() {
        scope.shown = false;
      };

      reset();

    }

  };
}

newObjectDialog.$inject = ['$resourceManager', '$notifier', '$location', '$rootScope', 'pluralizeFilter', '$objectsAvailable'];
