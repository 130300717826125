import "./ordersIndex.scss";

class Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;
  }

  $onInit() { this.search(1);}

  search(page) {

    this.loading = true;

    let params = {page: page};

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'orders', ...params}]).then(responses => {
      const [ res1 ] = responses;
      var { orders, meta } = res1;

      this.$api.fill(orders, 'orders', [
        ['hasOne', 'customer'],
      ]).then(filled => {
        this.orders = filled;
        this.updateViewControls(meta);
        this.loading = false;
      });

    });
  }

  updateViewControls(meta) {
    if (!this.orderBy) this.orderBy = new this.OrderBy(meta);
    if (!this.pagination) this.pagination = new this.Pagination(meta.total, this.search.bind(this));
    this.pagination.update();
  }
  
  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api'];

export const ordersIndex = {
  controller: Controller,
  template: require('./ordersIndex.html')
};