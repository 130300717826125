export const Pagination = () => {

  function _Pagination (totalCount, searchFn) {
    this.totalCount = totalCount;
    this.perPage = 40;
    this.lowerCount = 1;
    this.upperCount = (this.totalCount > this.perPage) ? this.perPage : this.totalCount;
    this.page = 1;
    this.searchFn = searchFn;
  }

  _Pagination.prototype = {
    next: function() {
      this.page = this.page + 1;
      this.search();
    },
    prev: function() {
      this.page = this.page - 1;
      this.search();
    },
    search: function() {
      this.searchFn(this.page);
    },
    lastPage: function() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    update: function() {
      this.lowerCount = (this.page - 1) * this.perPage + 1;
      this.upperCount = (this.totalCount <= this.lowerCount + this.perPage) ? this.totalCount : this.lowerCount + this.perPage - 1;
    }
  };

  return _Pagination;
}