import "./enquiriesIndex.scss";

class Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;

    this.filters = false;

    this.filterParams = {
      email_like: null,
      last_name_like: null,
      destination_like: null,
      status: null,
      consultant: null,
    }

    this.statuses = [
      {value: 'live', name: 'Live'},
      {value: 'quoted', name: 'Quoted'},
      {value: 'booked', name: 'Booked'},
      {value: 'no_reply', name: 'No reply'},
      {value: 'failed', name: 'Failed'},
    ];

    this.consultants = [
      {value: 'unassigned', name: 'Unassigned'},
      {value: 'vicky', name: 'Vicky'},
      {value: 'sally', name: 'Sally'},
    ];

  }

  $onInit() {
    this.search(1);
  }

  getHasFiltered() {
    return (_.compact(Object.values(this.filterParams)).length > 0)
  }

  toggleFilters() {
    if (this.filters) {
      const hasFiltered = this.getHasFiltered(); 
      this.filterParams = {
        email_like: null,
        last_name_like: null,
        destination_like: null,
        status: null,
        consultant: null,
      }
      if (hasFiltered) this.filter();
    }
    this.filters = !this.filters;
  }

  filter() {
    this.search(1, true);
  }

  search(page, reInitViewControls) {

    this.loading = true;

    let params = {page: page};

    Object.keys(this.filterParams).forEach(key => {
      const value = this.filterParams[key];
      if (value) params[key] = value;
    });

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'enquiries', ...params}]).then(responses => {
      const [ res1 ] = responses;
      var { enquiries, meta } = res1;

      this.$api.fill(enquiries, 'enquiries', [
        ['hasOne', 'customer'],
      ]).then(filled => {
        this.enquiries = filled;
        console.log('done');

        this.updateViewControls(meta, reInitViewControls);
        this.loading = false;
      });
    });
  }

  updateViewControls(meta, reInitViewControls) {
    if (!this.orderBy || reInitViewControls) this.orderBy = new this.OrderBy(meta);
    if (!this.pagination || reInitViewControls) this.pagination = new this.Pagination(meta.total, this.search.bind(this));
    this.pagination.update();
  }
  
  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api'];

export const enquiriesIndex = {
  controller: Controller,
  template: require('./enquiriesIndex.html')
};