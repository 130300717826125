class Controller {

  constructor($resourceManager, Pagination, OrderBy, $q, $ui, $api) {
    this.$resourceManager = $resourceManager;
    this.Pagination = Pagination;
    this.OrderBy = OrderBy; 
    this.$q = $q;
    this.ui = $ui;
    this.$api = $api;
    this.loading = true;
  }

  $onInit() { this.search(1);}

  filter() {
    this.search(1, true);
  }

  search(page, reInitViewControls) {

    this.loading = true;

    let params = {page: page};

    if (this.name_like) params.name_like = this.name_like;

    if (this.orderBy) {
      params.order_by = this.orderBy.order_by;
      params.order_direction = this.orderBy.order_direction;
    }

    this.$api.get([{name: 'participants', ...params}]).then(responses => {
      const [ res1 ] = responses;
      var { participants, meta } = res1;

      this.participants = participants;
      this.loading = false;

      this.updateViewControls(meta, reInitViewControls);
    });
  }

  updateViewControls(meta, reInitViewControls) {
    if (!this.orderBy || reInitViewControls) this.orderBy = new this.OrderBy(meta);
    if (!this.pagination || reInitViewControls) this.pagination = new this.Pagination(meta.total, this.search.bind(this));
    this.pagination.update();
  }

  setOrderBy(column) {
    this.orderBy.setOrderBy(column);
    this.pagination.page = 1;
    this.search(this.pagination.page);
  };

}

Controller.$inject = ['$resourceManager', 'Pagination', 'OrderBy', '$q', '$ui', '$api'];

export const participantsIndex = {
  controller: Controller,
  template: require('./participantsIndex.html')
};