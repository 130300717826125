export const selectBox = ($resourceManager) => {

  return {
    restrict: 'E',
    scope: {
      selected: '=',
      optionClass: '@',
      selectOptions: '=',
      placeholder: '@',
      optionLabelProperty: '@'
    },
    template: require('./selectBox.html'),
    link: function(scope) {

      scope.$watch('selectOptions', function(options) {
        if (options) scope.items = options;
      });

      $resourceManager.request(scope.optionClass, 'query').then(function(response) {
        if (!scope.items) scope.items = response[scope.optionClass];
      });
    }
  };
}

selectBox.$inject = ['$resourceManager'];
