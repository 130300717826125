import "./splitButton.scss";

export const splitButton = function ($document, $timeout, $rootScope) {
  return {
    restrict: 'E',
    replace: true,
    transclude: {
      'default': 'defaultOption'
    },
    scope: {
      disabled: '=',
      label: '@',
      defaultId: '@',
      // onClickToggle: '='
    },
    template: require('./splitButton.html'),
    link: function (scope) {

      scope.isOpen = false;

      scope.open = function () {
        scope.isOpen = true;
        $rootScope.$emit(scope.name + ":open");
        $timeout(function(){
          $document.one("click", scope.close);
        }, 10);
      };

      scope.toggle = function () {
        // scope.onClickToggle();
        (scope.isOpen) ? scope.close() : scope.open();
      };

      scope.close = function () {
        $timeout(function(){
          scope.isOpen = false;
        });
        $rootScope.$emit("reset:" + scope.name + ":open");
      };

    }
  };
};

splitButton.$inject = ['$document', '$timeout', '$rootScope'];
