class Controller {
  
  constructor($notifier, $editState, $resourceManager, $stateParams, $q, $ui) {
    this.$notifier = $notifier;
    this.$editState = $editState;
    this.$resourceManager = $resourceManager;
    this.$stateParams = $stateParams;
    this.$q = $q;
    this.ui = $ui;

    this.loading = true;

    this.statuses = [
      {value: 'live', name: 'Live'},
      {value: 'quoted', name: 'Quoted'},
      {value: 'booked', name: 'Booked'},
      {value: 'no_reply', name: 'No reply'},
      {value: 'failed', name: 'Failed'},
    ];

    this.consultants = [
      {value: 'unassigned', name: 'Unassigned'},
      {value: 'vicky', name: 'Vicky'},
      {value: 'sally', name: 'Sally'},
    ];

    this.$q.all([
      this.$resourceManager.request('enquiries', 'get', {id: this.$stateParams.id}),
      this.$resourceManager.request('destinations', 'query'),
    ]).then(responses => {
      const [ enquiry, destinations ] = responses;

      this.enquiry = enquiry.enquiry;
      this.destinations = destinations.destinations;
 
      this.$resourceManager.request('customers', 'get', {id: this.enquiry.customer_id}).then(response => {
        this.customer = response.customer;
        this.loading = false;
      });

      this.build(this.enquiry);

    });
  }

  build(enquiry, isSaving) {
    this.enquiry.date_start = (enquiry.date_start) ? new Date(moment(enquiry.date_start).toString()) : null;
    this.enquiry.date_end = (enquiry.date_end) ? new Date(moment(enquiry.date_end).toString()) : null;
    this.enquiry.destination = _.findWhere(this.destinations, {id: enquiry.destination_id});
    if (this.enquiry.status === "unread" && !isSaving) this.setStatus("open", true);
  }

  onChange() {
    this.$editState.dirty = this.form.$dirty;
  }

  onChangeSave() {
    this.$editState.dirty = this.form.$dirty;
    this.save();
  }

  onSave(skipNotification, isSaving) {
    this.form.$setPristine();
    this.$notifier.green('Saved.');
    this.$editState.dirty = false;
    this.build(this.enquiry, isSaving);
  };

  onError(error) {
    this.$notifier.red('Error.');
    console.error(error);
  };

  save() {
    if (!this.form.$valid) return false; 
    const _enquiry = angular.copy(this.enquiry);
    this.$resourceManager.save('enquiries', {id: this.enquiry.id}, _enquiry).then(this.onSave.bind(this)).catch(this.onError.bind(this));
  };

  // setStatus(status, skipNotification) { 
  //   var _enquiry = { status: status };
  //   this.$resourceManager.save('enquiries', {id: this.enquiry.id}, _enquiry).then(() => {
  //     this.enquiry.status = status;
  //     this.onSave(skipNotification, true);
  //   }).catch(this.onError.bind(this));
  // };

}

Controller.$inject = ['$notifier', '$editState', '$resourceManager', '$stateParams', '$q', '$ui'];

export const enquiryShow = {
  controller: Controller,
  template: require('./enquiryShow.html')
}